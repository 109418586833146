import React, {useState} from "react";
import {Spinner} from "reactstrap";
import axios from "axios";
import useTranslations from "../../utils/useTranslations";

const proxyBaseURL = "https://5tfj2zmxq2.execute-api.us-east-1.amazonaws.com/bt-help-center";
const ppgProxyBaseURL = "https://677n9jm4t8.execute-api.us-east-1.amazonaws.com/HelpCenterProxy";

export default (props) => {
    const t = useTranslations(props.text)
    const [userEmail, updateEmail] = useState("");
    const [purchaseId, updatePurchaseId] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [purchaseIdError, setPurchaseIdError] = useState(false);
    const [isLoading, setLoading] = useState(false);

    //0 Enter Email
    //1 Confirmation Email Sent
    const [loginState, updateLoginState] = useState(0);

    function submitButtonActive(){

        if(loginState === 0){
            return validEmail();
        }else{
            return purchaseId.length > 0
        }
    }

    function validEmail() {
        return (/^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i.test(userEmail));
    }

    function emailChanged(event) {
        updateEmail(event.target.value)
    }

    function purchaseChanged(event) {
        updatePurchaseId(event.target.value)
    }

    function accessClick() {
        if(submitButtonActive()){
            if (loginState === 0) {
                sendPurchaseId();
            } else {
                getSubscriptionInformation()
            }
        }
    }

    function getSubscriptionInformation(){
        setPurchaseIdError(false)
        setLoading(true);
        const encodedEmail = encodeURIComponent(userEmail)
        axios.get(`${ppgProxyBaseURL}/user-subscription?email=${encodedEmail}&purchaseId=${purchaseId}`)
        .then((response) => {
            if(response.status !== 200) {
                setPurchaseIdError(true);
                return;
            }
            console.log(response.data);
            if (props.onSuccess !== undefined) {    
                props.closeLogin();
                let orders = response.data.ppg;
                let purchases = [];
                for (let subscription of response.data.cb) {
                    for (let item of subscription.Items) {
                        for (let purchaseItem of item.SubscriptionPurchaseItems) {
                            purchases.push({
                                name: item.ProductName,
                                nameExtension: item.ProductNameExtension,
                                purchaseId: purchaseItem.PurchaseId,
                                nextBillingDate: subscription.NextBillingDate,
                            })
                        }
                    }
                }
                let userData = {
                    email: userEmail,
                    purchases,
                    orders
                }
                props.onSuccess(userData);
            }
        })
        .catch(() => {
            setPurchaseIdError(true)
        })
        .finally(() => {
            setLoading(false);
        });
    }

    function sendPurchaseId() {
        setLoading(true);
        setEmailError(false)
        const encodedEmail = encodeURIComponent(userEmail);
        axios.get(`${proxyBaseURL}/confirmation?email=${encodedEmail}`)
            .finally(() => {
                setLoading(false);
                updateLoginState(1);
            })
    }

    function closeClick() {
        if (props.closeLogin !== undefined) {
            props.closeLogin();
        }
    }

    function keyPressed(event) {
        if (event.key === "Enter" && validEmail()) {
            accessClick();
        }
    }

    return <>
            <div className="help-center-user-login">

                <div className="popup-container">
                    <div className="close-holder">
                        <div className="close" onClick={closeClick}>X</div>
                    </div>

                    <div className="login-body text-center">
                        {loginState === 0 && <>
                            <div className="header">{t("enterPremiumEmail")}</div>
                            <div className="my-4">{t("enterPremiumEmailInfo")}</div>
                            <input type="email"
                                   placeholder={t("enterEmailPrompt")}
                                   value={userEmail}
                                   onChange={emailChanged}
                                   onKeyDown={keyPressed}
                            />


                        <label className={!emailError ? "hide-label" : ""}>
                            {t("enterPremiumEmailError")}
                        </label>
                        </>}




                        {loginState > 0 && <>

                            <div className="header">{t("verifyEmail")}</div>
                            <div className="my-4">{t("enterReferenceInfo",{email:userEmail})}</div>


                             <input type="text"
                                    placeholder={t("enterReferencePrompt")}
                                    value={purchaseId}
                                    onChange={purchaseChanged}
                                    className={purchaseIdError ? "error" : ""}
                                    onKeyDown={keyPressed}
                             />

                            <div className="font-italic my-4">
                                {t("enterReferenceSpam")}
                            </div>


                            <label className={!purchaseIdError ? "hide-label" : ""}>
                                {t("enterReferenceError")}
                            </label>
                        </>}


                        <div className={"access-button " + (submitButtonActive() ? "active" : "")} onClick={accessClick}>
                            <div>
                                {isLoading && <Spinner className={"spinner"} />}
                                {!isLoading && <>{loginState === 0 ? t("submit") : t("accessButton")}</>}
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </>
}
import React from "react"
import HelpCenterLinks from "../../components/help-center/helpCenterLinks";
import HelpCenterBlog from "../../components/help-center/helpCenterBlog"
import HelpCenterSearch from "../../components/help-center/helpCenterSearch";
import HelpCenterBreadCrumb from "../../components/help-center/helpCenterBreadCrumb";
import HelpCenterPremiumSupport from "../../components/help-center/helpCenterPremiumSupport";
import HelpCenterRating from "../../components/help-center/helpCenterRating";
import useTranslations from "../../utils/useTranslations";

export default ({data, pageContext: {article, nav, text}}) => {
    const t = useTranslations(text)

    function getBreadCrumb() {
        let breadCrumb = [{
            name: t("helpCenter"),
            link: HelpCenterLinks.homeLink,
            isLast: false,
        }];

        for (let i = 0; i < article.hierarchy.length; ++i) {
            let hierarchy = article.hierarchy[i];

            let link = "";

            if (hierarchy.type === "category") {
                link = HelpCenterLinks.solutionLink(hierarchy.data.language, hierarchy.data.id)
            }

            breadCrumb.push({
                name: article.hierarchy[i].data.name,
                link: link,
                isLast: i === article.hierarchy.length - 1
            })


        }
        return breadCrumb;

    }

    return (
        <>
        <div className={'help-center-article-single'}>
            <HelpCenterSearch  text={text}/>
            <HelpCenterBreadCrumb breadCrumb={getBreadCrumb()} />

            <div className="help-center-body">
                <div className="help-center-main">
                    <h1>{article.title}</h1>

                    <div dangerouslySetInnerHTML={{__html: article.description}}></div>
                </div>

                <div className="help-center-right">
                    <div className="holder">
                        <HelpCenterPremiumSupport  text={text}/>
                        <HelpCenterBlog  text={text}/>
                        <HelpCenterRating  text={text}/>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

